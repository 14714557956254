@tailwind base;
@tailwind components;
@tailwind utilities;

.grow {
  transition: .35s;
}

.grow:hover {
  transform: scale(1.1);
}